/* lazy image */
@import 'react-lazy-load-image-component/src/effects/blur.css';
/* scrollbar */
@import 'simplebar-react/dist/simplebar.min.css';

/* font family  noto sans arabic for arabic */
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100..900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2:wght@400..800&display=swap'); */

/* font family  quicksand for english */

/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@400&family=Quicksand:wght@400&display=swap') */
html {
    scroll-behavior: smooth;
  }
  